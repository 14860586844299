import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentItemComponent } from '../@shared-except-main/content-item/content-item.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTabsModule } from '@angular/material/tabs';
import { ListingCircleComponent } from './style-item/style-item.component';
import { PriceDisplayComponent } from '../@shared-except-main/price-display/price-display.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { OfferCouponComponent } from './offer-coupon/offer-coupon.component';
import { ListingCircleShimmerComponent } from '../@shared-except-main/listing-circle-shimmer/listing-circle-shimmer.component';
import { ContentItemShimmerComponent } from '../@shared-except-main/content-item-shimmer/content-item-shimmer.component';
import { SimilarProductsComponent } from '../@shared-except-main/similar-products/similar-product.component';
import { BagPageShimmerComponent } from '../@shared-except-main/bag-page-shimmer/bag-page-shimmer.component';
import { SharedModule } from '../@shared/shared.module';
import { StyleContainerComponent } from './style-container/style-container.component';
import { RouterModule } from '@angular/router';
import { CollectionListingFilterPageComponent } from './collection-listing-filter-page/collection-listing-filter-page';
import { FilterShimmerComponent } from './filter-shimmer/filter-shimmer.component';
import { MatIconModule } from '@angular/material/icon';
import { SimilarProductsContainerComponent } from './similar-products-container/similar-products-container.component';
import { SearchStyleItemComponent } from './search-style-item/search-style-item.component';
import { SearchStyleContainerComponent } from './search-style-container/search-style-container.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { NewOfferComponent } from './new-offer/new-offer.component';
import { SwiperModule } from 'swiper/angular';
import { ColListingCircleComponent } from './col-style-item/col-style-item.component';
import { ColStyleContainerComponent } from './col-style-container/col-style-container.component';
import { GiftwrapCustomizePopupComponent } from './offer-coupon/giftwrap-customize-popup/giftwrap-customize-popup.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PopupShimmerModule } from '../popup-shimmer/popup-shimmer-module';
import { CombinationStyleContainerComponent } from './combination-style-container/combination-style-container.component';
import { CombinationStyleItemComponent } from './combination-style-item/combination-style-item.component';
import { StyleV2ContainerComponent } from './style-v2-container/style-v2-container.component';
import { StyleV2ItemComponent } from './style-v2-item/style-v2-item.component';
import { OrderOperationFormComponent } from './order-operation-form/order-operation-form.component';

@NgModule({
    declarations: [
        BagPageShimmerComponent,
        ContentItemComponent,
        ContentItemShimmerComponent,
        ListingCircleComponent,
        ListingCircleShimmerComponent,
        OfferCouponComponent,
        PriceDisplayComponent,
        SimilarProductsComponent,
        StyleContainerComponent,
        CollectionListingFilterPageComponent,
        FilterShimmerComponent,
        SimilarProductsContainerComponent,
        SearchStyleItemComponent,
        SearchStyleContainerComponent,
        NewOfferComponent,
        ColListingCircleComponent,
        ColStyleContainerComponent,
        GiftwrapCustomizePopupComponent,
        CombinationStyleContainerComponent,
        CombinationStyleItemComponent,
        StyleV2ContainerComponent,
        StyleV2ItemComponent,
        OrderOperationFormComponent,
    ],
    exports: [
        BagPageShimmerComponent,
        ContentItemComponent,
        ContentItemShimmerComponent,
        ListingCircleComponent,
        ListingCircleShimmerComponent,
        OfferCouponComponent,
        PriceDisplayComponent,
        SimilarProductsComponent,
        StyleContainerComponent,
        CollectionListingFilterPageComponent,
        FilterShimmerComponent,
        SimilarProductsContainerComponent,
        SearchStyleItemComponent,
        SearchStyleContainerComponent,
        ColListingCircleComponent,
        ColStyleContainerComponent,
        CombinationStyleContainerComponent,
        CombinationStyleItemComponent,
        StyleV2ContainerComponent,
        StyleV2ItemComponent,
        OrderOperationFormComponent,
    ],
    imports: [
        CommonModule,
        FlexLayoutModule,
        MatTabsModule,
        MatCardModule,
        MatButtonModule,
        SharedModule,
        RouterModule,
        MatIconModule,
        MatExpansionModule,
        SwiperModule,
        MatCheckboxModule,
        PopupShimmerModule,
    ],
})
export class SharedExceptMainModule {}
