export const environment = {
  production: true,
  host: "https://api.playground.dailyobjects.com",
  //cdnHost: 'https://d1xpq6ltqcajqi.cloudfront.net',
  cdnHost: 'https://api.playground.dailyobjects.com',

  auth: {
    mechanism: "mobile",
    amplify: {
      region: 'ap-south-1',
      userPoolId: 'ap-south-1_DIYFaWGm6',
      userPoolWebClientId: '1prnum2mbclcoqp8oarrercvcf'
    }
  },
  WizzySearch: {
    endpoint: 'https://api.wizzy.ai/v1/products/search',
    StoreID: '2652f4a89abb11eca61d0a0c8095feae',
    APIKey: 'MHZxMENoUnJ0enoyR0RydGE2c291V1ZERk4yZTh3WnpiVytYOVF1Nm1lSTBmWHRBcGFlOWNlTGJrclVydlp2UXk4TkRnbmFQNFl5a0QyYS81U3BkSHc9PQ==',
  },
  zest: {
    Merchantid: 'e973d347-5c7c-4138-b033-7fabfdec52a3',
    PaymentGatewayId: 'df430858-087f-4cbe-aabd-9a92d9c6ba3a',
  },
  nector:{
    APIKey:'9f76bdf65089c0ab8c79a71fbc1c60f97798e77f1d9b2491335f2b3466d919ab'
  },
  logging: {
    apm: {
      endpoint: "https://apm-logs.dailyobjects.com"
    }
  },
  appVersion: require("../../package.json").version,
  firebaseConfig: {
    apiKey: "AIzaSyBW1nRn7O_ed9fV8oaH_iZHewm-yweoqhM",
    authDomain: "do-marche-non-production.firebaseapp.com",
    databaseURL: "https://do-marche-non-production.firebaseio.com",
    projectId: "do-marche-non-production",
    storageBucket: "do-marche-non-production.appspot.com",
    messagingSenderId: "37378853732",
    appId: "1:37378853732:web:4c7e4210f2adc0583ec871",
    measurementId: "G-ZEF8S8DMC1"
  },
  name: "dev-marche-web",
  payments: {
    paytm: {
      url: "https://securegw.paytm.in/theia/processTransaction"
    }
  },
  mixpanel: {
      token: '03b08e4fdc839717085c3dda147543c3',
    },
  trustedWebsiteCertificate: false
};
