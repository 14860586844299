import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { ResponseWrapper } from '../@shared/models/response-wrapper.model';
import { MarcheAPIService } from './api/marche-api.service';
import { MixPanelService } from './mixpanel.service';
import { MoEngageService } from './moengage.service';

@Injectable({ providedIn: 'root' })
export class UserIdentifyService {
    constructor(
        private marcheAPIService: MarcheAPIService,
        private mixPanelService: MixPanelService,
        private moEngageService: MoEngageService
    ) {}
    static readonly Identify = '/fn/userinfo';

    userIdentify() {
        const subscriber = Subscriber.create<ResponseWrapper<any> | null>(
            value => {
                if (value?.success) {
                    this.mixPanelService.peopleSet({
                        $name: value?.data?.name,
                        $email: value?.data?.email,
                        $phone: value?.data?.phone,
                        $birthday: value?.data?.birthday,
                    });
                    this.moEngageService.setUserAttributes(value?.data);
                    this.setActiveUser();
                } else {
                }
            },
            error => {},
            () => {}
        );

        this.marcheAPIService
            .getRequest<ResponseWrapper<any> | null>(
                UserIdentifyService.Identify
            )
            .subscribe(subscriber);
    }

    setActiveUser() {
        const userActiveFlag = sessionStorage.getItem('isUserActive');
        const userId = localStorage.getItem('username');
        if (!userActiveFlag && userId) {
            this.moEngageService.logIn(userId);
        } else {
            sessionStorage.setItem('isUserActive', 'true');
        }
    }
}
