import { Injectable } from '@angular/core';
declare const Moengage: any;

@Injectable({
    providedIn: 'root',
})
export class MoEngageService {
    constructor() {}

    // Set user attributes
    setUserAttributes(user: any) {
        Moengage.add_email(user.email);
        Moengage.add_mobile(user.phone);
        Moengage.add_user_name(user.name);
        Moengage.add_birthday(new Date(user.birthday)); // Pass a Date object
    }

    // Set unique user ID
    logIn(userId: string | null) {
        Moengage.add_unique_user_id(userId);
    }

    // Log out user
    logOut() {
        Moengage.destroy_session();
    }

    track(eventName: string, attributes?: any) {
        Moengage.track_event(eventName, attributes);
    }
}
